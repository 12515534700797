.chart-mixed-btn{
    outline:0;
    border-width: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

}
.chart-mixed-btn:hover{
    filter: brightness(95%);
    outline: 0;
}
.chart-mixed-btn.hidden-btn-chart {
    filter: brightness(85%);
    outline: 0;
}